
import { defineComponent, ref, reactive } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import ContactsImportMatchSelect from '@/components/pages/contacts/import/flow/ContactsImportMatchSelect.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ContactsImportMatchSelect,
    TmEllipsis,
    TmTable,
  },
  setup() {
    const headers = reactive({
      headerFirstNameDefault: {
        label: 'Do not import',
        red: true,
      },
      headerLastNameDefault: {
        label: 'Do not import',
        red: true,
      },
      headerPhoneNumberDefault: {
        label: 'Phone number',
        red: false,
      },
    })

    const headerOptions = ref([
      {
        label: 'First name',
        red: false,
      },
      {
        label: 'Last name',
        red: false,
      },
      {
        label: 'Phone number',
        red: false,
      },
    ])

    const contactsHeaders = ref<TableHeaders[]>([
      {
        text: 'First name',
        value: 'first-name',
        width: 200,
        hideSortable: true,
      },
      {
        text: 'Last name',
        value: 'last-name',
        width: 200,
        hideSortable: true,
      },
      {
        text: 'Phone',
        value: 'phone',
        width: 250,
        hideSortable: true,
      },
    ])

    const contacts = ref(getTableData('contactsLarge'))

    return {
      contactsHeaders,
      contacts,
      headers,
      headerOptions,
    }
  },
})
