
import { defineComponent, ref, computed } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFile from '@/components/shared/TmFile.vue'
import { getFile, getFileSize } from '@/services/file'

export default defineComponent({
  components: {
    TmButton,
    TmProgress,
    TmSpinner,
    TmAlert,
    TmFile,
  },
  props: {
    fileFormats: {
      type: Array,
      default: () => ['csv', 'xlsx', 'xls'],
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    uploadingPercentage: {
      type: Number,
      default: 0,
    },
    file: {
      type: Object,
    },
    color: {
      type: String,
      default: '',
    },
  },
  emits: ['uploadingStatus', 'fileContext', 'uploadSuccess'],
  setup(props, context) {
    const fileInput = ref()
    const uploadDragOver = () => {
      fileInput.value.classList.add('upload-file__wrapper--active')
    }

    const uploadMouseLeave = () => {
      fileInput.value.classList.remove('upload-file__wrapper--active')
    }

    const fileFormat = computed(() => {
      return getFile(props.file?.name)
    })

    const fileSize = computed(() => {
      return getFileSize(props.file?.size)
    })

    const errorState = ref(false)

    const fileUpdate = (e: any) => {
      if (!checkFileFormat(e.name)) return

      context.emit('fileContext', e)
    }

    const checkFileFormat = (format: string) => {
      const status = props.fileFormats.includes(format.split('.').pop())
      errorState.value = !status

      return status
    }

    const dragAndDrop = (e: any) => {
      e.preventDefault()
      fileUpdate(e.dataTransfer.files[0])
      uploadMouseLeave()
    }

    const uploadFile = (e: any) => {
      fileUpdate(e.target.files[0])
    }

    return {
      dragAndDrop,
      uploadFile,
      errorState,
      fileFormat,
      fileSize,
      fileInput,
      uploadDragOver,
      uploadMouseLeave,
    }
  },
})
