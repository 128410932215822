
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import ContactsImportMatchTable from '@/components/pages/contacts/import/flow/ContactsImportMatchTable.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import ContactsImportNumberValidationModal
  from '@/components/modals/contacts/import/ContactsImportNumberValidationModal.vue'
import CarrierLookupMatchTable from '@/components/pages/services/carrierLookup/flow/CarrierLookupMatchTable.vue'
import type { MatchTable } from '@/definitions/shared/types'

export default defineComponent({
  name: 'MatchFieldsStep',
  components: {
    CarrierLookupMatchTable,
    ContactsImportNumberValidationModal,
    TmButton,
    ContactsImportMatchTable,
    FlowStep,
  },
  props: {
    step: {
      type: Number,
      default: 2,
    },
    title: {
      type: String,
      default: 'Match fields',
    },
    editButton: {
      type: String,
      default: 'Edit',
    },
    matchTable: {
      type: String as PropType<MatchTable>,
      default: 'contacts',
    },
    subtitleMatchTableModal: {
      type: String,
      default: 'Please select the relevant user fields to match the spreadsheet data columns.',
    },
  },
  emits: ['step-change'],
  setup(props) {
    const { openModal } = useModals()

    const matchTables: Record<string, string> = {
      contacts: 'ContactsImportMatchTable',
      carrierLookup: 'CarrierLookupMatchTable',
    }
    const matchTableName = computed<string>(() => {
      return matchTables[props.matchTable]
    })

    const matchTableOpenModal = () => {
      openModal('matchTable', {
        modalTitle: 'Match data fields',
        matchTable: props.matchTable,
        subtitle: props.subtitleMatchTableModal,
      })
    }

    return {
      openModal,
      matchTableName,
      matchTableOpenModal,
    }
  },
})
