
import { defineComponent, reactive } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'

export default defineComponent({
  name: 'ContactsImportNumberValidation',
  components: {
    TmAlert,
    TmFormLine,
  },
  setup() {
    const state = reactive({
      country: phoneCountryVariant[0],
      numberFormat: 'sameCountry',
    })

    return {
      state,
    }
  },
})
