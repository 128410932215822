
import { defineComponent, ref, computed, watch } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import type { ListChip } from '@/definitions/shared/types'
import { contactListsOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  name: 'AdditionalOptionsStep',
  components: {
    TmAlert,
    TmFormLine,
    TmEllipsis,
    FlowStep,
  },
  setup() {
    const lists = ref<ListChip[]>([contactListsOptions[0]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])
    const excludeLandline = ref(false)
    const selectedActionValue = ref([])
    const selectedAction = computed({
      get: () => selectedActionValue.value,
      set: (val) => {
        selectedActionValue.value = val.slice(-1)
      },
    })

    watch(() => selectedAction.value, (val) => {
      if (val.length) {
        lists.value = [
          {
            label: val[0] === 'block' ? 'Blocked' : 'Unsubscribers',
            style: 'warning',
            icon: val[0] === 'block' ? 'block' : 'remove_circle',
          },
        ]
      } else {
        lists.value = [contactListsOptions[0]]
      }
    })

    watch(() => lists.value, (val) => {
      if (!val.length && selectedAction.value.length) {
        selectedActionValue.value = []
        lists.value = [contactListsOptions[0]]
      }
    })

    return {
      selectedAction,
      selectedActionValue,
      lists,
      listsOptions,
      excludeLandline,
    }
  },
})
