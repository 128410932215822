
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: { TmButton },
  props: {
    items: {
      type: Array,
    },
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
})
