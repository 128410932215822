
import type { PropType } from 'vue'
import { defineComponent, watch } from 'vue'
import { loadFile } from '@/compositions/loadFile'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { RouteLocationRaw } from 'vue-router'
import ContactsImportFile from '@/components/pages/contacts/import/flow/ContactsImportFile.vue'

export default defineComponent({
  name: 'ImportResultsStep',
  components: {
    ContactsImportFile,
    TmButton,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Import results',
    },
    subtitle: {
      type: String,
      default: 'Just leave it with us and we will import your data in the background.',
    },
    uploadingName: {
      type: String,
    },
    uploadingFinishedText: {
      type: String,
    },
    file: {
      type: Object,
    },
    viewResultButton: {
      type: String,
      default: 'View results',
    },
    viewResultLink: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({ name: 'base.contacts.import.details' }),
    },
    createAnotherButton: {
      type: String,
      default: 'Start new import',
    },
  },
  emits: ['finishLoading'],
  setup(props, context) {
    const { uploadingPercentage, uploadingStatus, uploadingProgress } = loadFile()
    watch(
      () => uploadingStatus.value,
      (val: boolean) => {
        if (!val) {
          context.emit('finishLoading')
        }
      }
    )

    return {
      uploadingPercentage,
      uploadingStatus,
      uploadingProgress,
    }
  },
})
