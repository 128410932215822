import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d27937e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tiles-switch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-left flex-grow-1" }
const _hoisted_4 = { class: "d-block mb-6px subhead-semi-bold-16 lh-24 emphasize--text" }
const _hoisted_5 = { class: "d-flex justify-between sm-flex-column lh-20" }
const _hoisted_6 = { class: "emphasize--text" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item[_ctx.itemKey] || i,
        class: _normalizeClass(["tiles-switch__item", [
        { 'tiles-switch__item--active': _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] },
      ]]),
        type: "button",
        onClick: ($event: any) => (_ctx.onClick(item))
      }, [
        _renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps({
          item,
          index: i,
          active: _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey],
        })), () => [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex", { 'flex-column': _ctx.contentColumn }])
          }, [
            _createVNode(_component_tm_icon, {
              class: _normalizeClass([
              _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] ? 'text-primary' : 'distinct--text',
              _ctx.contentColumn ? 'mb-10px' : 'mr-10px'
            ]),
              name: item.icon,
              "custom-size": _ctx.contentColumn ? '40px' : '24px'
            }, null, 8, ["class", "name", "custom-size"]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "title", {
                title: item.title
              }, () => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1)
              ], true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(item.description), 1),
                (_ctx.$slots.button)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _renderSlot(_ctx.$slots, "button", { item: item }, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2)
        ], true),
        (_ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] && _ctx.$slots['active-footer'])
          ? _renderSlot(_ctx.$slots, "active-footer", _normalizeProps(_mergeProps({ key: 0 }, {
          item,
        })), undefined, true)
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}