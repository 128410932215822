import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_import_file = _resolveComponent("contacts-import-file")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_flow_step = _resolveComponent("flow-step")!

  return (_openBlock(), _createBlock(_component_flow_step, {
    title: _ctx.title,
    subtitle: _ctx.subtitle,
    onStepActive: _ctx.uploadingProgress
  }, {
    subtitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_tm_button, {
        color: "primary",
        size: "large",
        to: _ctx.viewResultLink,
        disable: _ctx.uploadingStatus
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.viewResultButton), 1)
        ]),
        _: 1
      }, 8, ["to", "disable"]),
      _createVNode(_component_tm_button, {
        size: "large",
        disable: _ctx.uploadingStatus
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.createAnotherButton), 1)
        ]),
        _: 1
      }, 8, ["disable"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_contacts_import_file, {
        file: _ctx.file,
        "uploading-percentage": _ctx.uploadingPercentage,
        "uploading-status": _ctx.uploadingStatus,
        "uploading-name": _ctx.uploadingName,
        "uploading-finished-text": _ctx.uploadingFinishedText
      }, null, 8, ["file", "uploading-percentage", "uploading-status", "uploading-name", "uploading-finished-text"])
    ]),
    _: 1
  }, 8, ["title", "subtitle", "onStepActive"]))
}