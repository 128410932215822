
import { defineComponent, ref, computed } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import UploadDataStep from '@/components/pages/contacts/import/flow/steps/UploadDataStep.vue'
import MatchFieldsStep from '@/components/pages/contacts/import/flow/steps/MatchFieldsStep.vue'
import AdditionalOptionsStep from '@/components/pages/contacts/import/flow/steps/AdditionalOptionsStep.vue'
import ImportResultsStep from '@/components/pages/contacts/import/flow/steps/ImportResultsStep.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { UploadMethod } from '@/definitions/shared/types'

export default defineComponent({
  name: 'ContactsImportFlow',
  components: { ImportResultsStep, AdditionalOptionsStep, MatchFieldsStep, UploadDataStep, FlowStepList, TmButton, DetailsHero, PageBlock },
  setup() {
    const { isMdMax, isSmMax } = useBreakpoints()
    const breadcrumbs = [
      { label: 'Contacts', name: 'base.contacts.import' },
      { label: 'Imports', name: 'base.contacts.import.details' },
      { label: 'New import' },
    ]
    const currentMethod = ref('file')
    const currentStep = ref(1)
    const isImportFinished = ref(false)
    const file = ref()

    const uploadMethods = computed<UploadMethod[]>(() => [
      {
        type: 'file',
        icon: 'tmi-attachment',
        title: 'Upload a file',
        description: 'Upload a file with contacts phone numbers, names, organization etc.',
      },
      {
        type: 'current-app',
        icon: 'tmi-duplicate',
        title: isSmMax.value ? 'Copy & paste' : 'Copy and paste phone numbers',
        description: 'Simply copy and paste multiple numbers to add many contacts at ones (no field matching).',
      },
    ])

    return {
      isMdMax,
      breadcrumbs,
      currentMethod,
      currentStep,
      isImportFinished,
      file,
      uploadMethods,
    }
  },
})
