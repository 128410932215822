
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import { getFileSize } from '@/services/file'
import { loadFile } from '@/compositions/loadFile'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmUploadArea from '@/components/shared/TmUploadArea.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTilesSwitch from '@/components/shared/responsive/tilesSwitch/TmTilesSwitch.vue'
import type { UploadMethod } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'UploadDataStep',
  components: {
    TmTilesSwitch,
    TmDropdown,
    TmDropdownItem,
    TmButton,
    TmUploadArea,
    FlowStep,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Upload contact data',
    },
    subtitle: {
      type: String,
      default: 'Select a way how you would like to upload contacts.',
    },
    editButton: {
      type: String,
      default: 'Start over',
    },
    methodsList: {
      type: Array as PropType<UploadMethod[]>,
      required: true,
    },
    copyPasteItemName: {
      type: String,
      default: 'phone number',
    },
  },
  emits: ['update-method', 'upload-file', 'step-change'],
  setup(props, context) {
    const { isSmMax } = useBreakpoints()
    const file = ref()
    const emails = ref([])
    const methods = computed(() => {
      return props.methodsList.slice(0, file.value ? 1 : 2)
    })

    const methodValue = ref<UploadMethod>(methods.value[0])

    const fileSize = computed(() => {
      return getFileSize(file.value?.size)
    })

    const uploadFile = (e: any) => {
      file.value = e
      context.emit('upload-file', e)
    }

    const nextStep = () => {
      context.emit('step-change', props.step + 1)
    }
    const { uploadingPercentage, uploadingStatus, uploadingProgress } = loadFile(nextStep)

    return {
      isSmMax,
      file,
      methods,
      methodValue,
      emails,
      uploadingPercentage,
      uploadingStatus,
      uploadingProgress,
      fileSize,
      uploadFile,
    }
  },
})
