
import { defineComponent, computed } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmTilesSwitchDesktop from '@/components/shared/responsive/tilesSwitch/TmTilesSwitchDesktop.vue'
import TmTilesSwitchMobile from '@/components/shared/responsive/tilesSwitch/TmTilesSwitchMobile.vue'

export default defineComponent({
  components: {
    TmTilesSwitchDesktop,
    TmTilesSwitchMobile,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const componentName = computed(() => isSmMax.value ? 'tm-tiles-switch-mobile' : 'tm-tiles-switch-desktop')

    return {
      componentName,
    }
  },
})
