import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbd6be02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tiles-switch" }
const _hoisted_2 = { class: "tiles-switch__panel" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tiles-switch__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createElementBlock("button", {
          key: item[_ctx.itemKey] || i,
          class: _normalizeClass(["tiles-switch__item", [
          { 'tiles-switch__item--active': _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] },
        ]]),
          type: "button",
          onClick: ($event: any) => (_ctx.onClick(item))
        }, [
          _renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps({
            item,
            index: i,
            active: _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey],
          })), () => [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "title", {
                title: item.title
              }, () => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ], true)
            ])
          ], true)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item[_ctx.itemKey] || i
      }, [
        (_ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] && _ctx.$slots['active-footer'])
          ? _renderSlot(_ctx.$slots, "active-footer", _normalizeProps(_mergeProps({ key: 0 }, {
          item,
        })), undefined, true)
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}