
import { defineComponent, computed } from 'vue'
import TmFile from '@/components/shared/TmFile.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import { getFile, getFileSize } from '@/services/file'
import { capitalize } from '@/services/utils'

export default defineComponent({
  components: {
    TmFile,
    TmSpinner,
    TmProgress,
  },
  props: {
    file: {
      type: Object,
    },
    uploadingPercentage: {
      type: Number,
    },
    uploadingStatus: {
      type: Boolean,
    },
    uploadingFinishedText: {
      type: String,
    },
    uploadingName: {
      type: String,
      default: 'import',
    },
  },
  setup(props) {
    const fileFormat = computed(() => {
      return props.file ? getFile(props.file.name) : 'csv'
    })

    const fileSize = computed(() => {
      return props.file ? getFileSize(props.file?.size) : '88KB'
    })

    return {
      fileFormat,
      fileSize,
      capitalize,
    }
  },
})
