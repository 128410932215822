
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import ContactsImportMatchSelect from '@/components/pages/contacts/import/flow/ContactsImportMatchSelect.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'

type HeaderType = {
  [key: string]: {
    label: string;
    red: boolean;
  }
}

export default defineComponent({
  components: {
    ContactsImportMatchSelect,
    TmEllipsis,
    TmTable,
  },
  setup() {
    const { openModal } = useModals()
    const headers = reactive<HeaderType>({
      headerFirstNameDefault: {
        label: 'First name',
        red: false,
      },
      headerLastNameDefault: {
        label: 'Last name',
        red: false,
      },
      headerEmailDefault: {
        label: 'Email',
        red: false,
      },
      headerPhoneNumberDefault: {
        label: 'Phone number',
        red: false,
      },
      headerAddressDefault: {
        label: 'Do not import',
        red: true,
      },
      headerCountryDefault: {
        label: 'Do not import',
        red: true,
      },
      headerOrganizationDefault: {
        label: 'Do not import',
        red: true,
      },
    })
    const isHeadersMatchExist = computed(() => {
      const headersArr = Object.keys(headers).map((k: string) => headers[k])

      const headersMatch = headersArr.reduce((acc: any, item) => {
        if (item.label !== 'Do not import') {
          acc[item.label] = acc[item.label] ? acc[item.label] + 1 : 1
        }

        return acc
      }, {})

      const result = Object.keys(headersMatch).filter((item) => headersMatch[item] > 1)

      return !!result.length
    })

    const headerOptions = ref([
      {
        label: 'First name',
        red: false,
      },
      {
        label: 'Last name',
        red: false,
      },
      {
        label: 'Email',
        red: false,
      },
      {
        label: 'Phone number',
        red: false,
      },
      {
        label: 'Address',
        red: false,
      },
      {
        label: 'Country',
        red: false,
      },
      {
        label: 'Organization',
        red: false,
      },
    ])

    const contactsHeaders = ref<TableHeaders[]>([
      {
        text: 'First name',
        value: 'first-name',
        width: 200,
        hideSortable: true,
      },
      {
        text: 'Last name',
        value: 'last-name',
        width: 200,
        hideSortable: true,
      },
      {
        text: 'Email',
        value: 'email',
        width: 250,
        hideSortable: true,
      },
      {
        text: 'Phone',
        value: 'phone',
        width: 250,
        hideSortable: true,
      },
      {
        text: 'Address',
        value: 'address',
        width: 250,
        hideSortable: true,
      },
      {
        text: 'Country',
        value: 'country',
        width: 250,
        hideSortable: true,
      },
      {
        text: 'Organization',
        value: 'organization',
        width: 250,
        hideSortable: true,
      },
    ])

    watch(() => isHeadersMatchExist.value, (value) => {
      if (value) {
        openModal('confirmation', {
          text: 'This field is already in use by another column. Do you want the selected column to use this field instead?',
          btnText: 'Continue',
          title: 'Contact field is already in use',
        })
      }
    })

    const contacts = ref(getTableData('contactsLarge'))

    return {
      contactsHeaders,
      contacts,
      headers,
      isHeadersMatchExist,
      headerOptions,
    }
  },
})
