
import type { PropType } from 'vue'
import type { UploadMethod } from '@/definitions/shared/types'
import { defineComponent, toRefs } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'TmTilesSwitch',
  props: {
    modelValue: {
      type: Object as PropType<UploadMethod>,
    },
    contentColumn: {
      type: Boolean,
    },
    emitValue: {
      type: Boolean,
    },
    offset: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    itemKey: {
      type: String as PropType<keyof UploadMethod>,
      default: 'title',
    },
    items: {
      type: Array as PropType<UploadMethod[]>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const { itemKey, emitValue } = toRefs(props)
    const { isSmMax } = useBreakpoints()

    const onClick = (val: UploadMethod) => {
      if (emitValue.value) {
        context.emit('update:modelValue', val[itemKey.value])
      } else {
        context.emit('update:modelValue', val)
      }
    }

    return {
      onClick,
      isSmMax,
    }
  },
})
