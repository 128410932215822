
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ContactsImportNumberValidation from '@/components/modals/contacts/import/ContactsImportNumberValidation.vue'

export default defineComponent({
  name: 'ContactsImportNumberValidationModal',
  components: {
    ContactsImportNumberValidation,
    TmButton,
    TmModal,
  },
  props: {
    title: {
      type: String,
      default: 'Number validation',
    },
  },
  emits: ['onSave'],
})
