import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_contacts_import_match_select = _resolveComponent("contacts-import-match-select")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.contactsHeaders,
    items: _ctx.contacts,
    class: "match-table",
    "item-key": "email",
    "items-name": "contacts",
    "hide-footer": "",
    "hide-pagination": "",
    "hide-actions": "",
    "hide-phantom-col": "",
    bordered: ""
  }, {
    "body-cell-first-name": _withCtx((props) => [
      _createVNode(_component_tm_ellipsis, { "min-width": "150px" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.person.firstName), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-last-name": _withCtx((props) => [
      _createVNode(_component_tm_ellipsis, { "min-width": "150px" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.person.lastName), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-phone": _withCtx((props) => [
      _createVNode(_component_tm_ellipsis, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.phone), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "header-cell-first-name": _withCtx(() => [
      _createVNode(_component_q_th, {
        class: _normalizeClass({ '_red': _ctx.headers.headerFirstNameDefault.red })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_contacts_import_match_select, {
            modelValue: _ctx.headers.headerFirstNameDefault,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.headers.headerFirstNameDefault) = $event)),
            items: _ctx.headerOptions
          }, null, 8, ["modelValue", "items"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    "header-cell-last-name": _withCtx(() => [
      _createVNode(_component_q_th, {
        class: _normalizeClass({ '_red': _ctx.headers.headerLastNameDefault.red })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_contacts_import_match_select, {
            modelValue: _ctx.headers.headerLastNameDefault,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.headers.headerLastNameDefault) = $event)),
            items: _ctx.headerOptions
          }, null, 8, ["modelValue", "items"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    "header-cell-phone": _withCtx(() => [
      _createVNode(_component_q_th, {
        class: _normalizeClass({ '_red': _ctx.headers.headerPhoneNumberDefault.red })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_contacts_import_match_select, {
            modelValue: _ctx.headers.headerPhoneNumberDefault,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.headers.headerPhoneNumberDefault) = $event)),
            items: _ctx.headerOptions
          }, null, 8, ["modelValue", "items"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}